<template>
  <button :class="[customClass]" @click="$emit('onClick')">
    <span>{{ description }}</span>
    <div />
  </button>
</template>

<script>
export default {
  name: 'GoetelButton',
  props: {
    customClass: {
      type: String,
      required: false,
      default: 'normal'
    },
    description: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
button {
  background: transparent;
  border: none;
  text-transform: uppercase;
  font-size: var(--goe-fontSize-small);
  cursor: pointer;
  padding: var(--goe-spacing-1);
}

button.small {
  font-size: var(--goe-fontSize-verySmall);
}

button > div {
  height: var(--goe-spacing-0_5);
  background: transparent;
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-top: var(--goe-spacing-0_5);
}

button:hover > div,
button:focus > div,
button:active > div {
  background: var(--goe-background-dark);
}
</style>
