<template>
  <v-tooltip v-if="fullLink !== null" top>
    <template v-slot:activator="{ on, attrs }">
      <a
        :href="fullLink"
        v-bind="attrs"
        target="_blank"
        v-on="on"
      >
        <slot> Link-Text hinzufügen! </slot><v-icon dense color="var(--goe-fontColor-highlight)">{{
          iconName
        }}</v-icon>
      </a>
    </template>
    <span>{{ tooltipText }}</span>
  </v-tooltip>
  <span v-else><slot>Link-Text hinzufügen!</slot></span>
</template>
<script>
export default {
  name: 'TextLink',
  props: {
    link: {
      type: String,
      required: false,
      default: null
    },
    linkType: {
      type: String,
      required: false,
      default: 'other',
      validator: (prop) => ['ocs', 'otrs', 'other'].includes(prop)
    },
    linkObject: {
      type: String,
      default: null,
      required: false
    },
    tooltip: {
      type: String,
      required: false,
      default: null
    }
  },
  computed: {
    fullLink() {
      if (this.linkType === 'other' && this.link !== null) {
        return this.link;
      } else if (this.linkObject !== null) {
        let baselink;
        if (this.linkType === 'ocs') {
          baselink = 'https://ocs.outbox.de/wbci/furtherInformation/';
        } else {
          // otrs
          baselink =
            'https://otrs.goetel.de/ofork/index.pl?Action=AgentCustomerInformationCenter;CustomerID=';
        }
        return baselink + this.linkObject;
      } else {
        return null;
      }
    },
    iconName() {
      if (this.linkType === 'ocs') {
        return 'mdi-alpha-o-circle';
      } else if (this.linkType === 'otrs') {
        return 'mdi-ticket';
      } else {
        return 'mdi-link-variant';
      }
    },
    tooltipText() {
      if (this.tooltip !== null) {
        return this.tooltip;
      } else if (this.linkType === 'ocs') {
        return 'WBCI-Prozess in OCS anzeigen';
      } else if (this.linkType === 'otrs') {
        return 'Kunde in OTRS anzeigen';
      } else {
        return 'Link-Tooltip hinzufügen!';
      }
    }
  }
};
</script>
<style scoped></style>
