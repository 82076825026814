<template>
  <v-text-field
    ref="inputField"
    v-model="field.text"
    :label="field.label"
    :placeholder="field.placeholder"
    outlined
    dense
    :disabled="disabled"
    :rules="ruleSet"
    :errorMessages="errorMessage"
    @input="$emit('change', field.text)"
  />
</template>

<script>
export default {
  props: {
    errorMessage: {
      type: String,
      default: null,
      required: false
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    fields: {
      type: Object,
      label: { required: true },
      text: { default: '' },
      default() {
        return {
          text: '',
          label: 'Text'
        };
      }
    }
  },
  data() {
    return {
      field: this.fields,
      ruleSet: [
        // for textData: {required: true}
        (v) => {
          if (this.fields.required) {
            return !!v || `${this.fields.label} ist erforderlich!`;
          } else return true;
        },
        // for textData: {number: true}
        (v) => {
          if (this.fields.number) {
            return (
              (!!v && !isNaN(v)) || 'Es muss eine Nummer angegeben werden.'
            );
          } else return true;
        },
        // for textData: {min: 10}
        (v) => {
          if (this.fields.min) {
            return (
              (v && v.length >= this.fields.min) ||
              `Es müssen mind. ${this.fields.min} Zeichen sein.`
            );
          } else return true;
        }
      ]
    };
  },
  created() {
    this.$emit('fields', this.field);
  }
};
</script>

<style></style>
