<template>
  <div class="button-minimal-size">
    <v-dialog
      v-model="modalActive"
      width="40%"
      :retain-focus="false"
      class="button-minimal-size"
    >
      <template v-slot:activator="{ on: dialog, attrs: dialogAttrs }">
        <v-tooltip
          :top="tooltipTop"
          :bottom="tooltipBottom"
          :left="tooltipLeft"
        >
          <template v-slot:activator="{ on: tooltip, attrs: tooltipAttrs }">
            <div v-bind="tooltipAttrs" v-on="tooltip">
              <v-btn
                :class="[customButtonClass]"
                :disabled="disabled"
                v-bind="{
                  ...dialogAttrs,
                  ...triggerButtonProps
                }"
                v-on="dialog"
                @click="$emit('onDialogOpen')"
              >
                <v-icon :small="smallTriggerIcon">
                  {{ iconName }}
                </v-icon>
                <slot name="triggerButtonText"></slot>
              </v-btn>
            </div>
          </template>
          <span> {{ tooltipText }} </span>
        </v-tooltip>
      </template>
      <v-card>
        <v-card-title>
          <slot name="dialogTitle">
            Dialogtitel Slot (Name: dialogTitle)
          </slot>
        </v-card-title>
        <v-card-text>
          <slot name="dialogText">
            Dialog Text Slot (Name: dialogText)
          </slot>
        </v-card-text>
        <v-divider />
        <v-card-actions>
          <v-spacer />
          <v-btn color="primary" @click="emitCancel()">
            Abbrechen
          </v-btn>
          <v-btn
            :color="confirmButtonColor"
            :disabled="confirmButtonDisabled"
            @click="emitConfirm()"
          >
            {{ confirmButtonText }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
export default {
  name: 'DialogButtonWithTooltip',
  props: {
    tooltipTop: {
      type: Boolean,
      default: false,
      required: false
    },
    tooltipBottom: {
      type: Boolean,
      default: false,
      required: false
    },
    tooltipLeft: {
      type: Boolean,
      default: false,
      required: false
    },
    tooltipRight: {
      type: Boolean,
      default: false,
      required: false
    },
    // icon name for the trigger-button icon.
    iconName: {
      type: String,
      required: true
    },
    tooltipText: {
      type: String,
      required: true
    },
    // Object that can contain props that will be directly set on the v-button triggering the dialog
    triggerButtonProps: {
      type: Object,
      required: false,
      default: () => {
        return { icon: true };
      }
    },
    confirmButtonColor: {
      type: String,
      required: false,
      default: 'primary'
    },
    confirmButtonText: {
      type: String,
      required: true
    },
    // Boolean prop that can be used to disable the confirmation button in the dialog.
    confirmButtonDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    // Custom classes (comma separated) for the button that triggers the dialog.
    customButtonClass: {
      type: String,
      required: false,
      default: null
    },
    // If true, the icon of the trigger button will get the 'small' prop
    smallTriggerIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    // If true, the trigger-button will be disabled.
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    modalActive: false
  }),
  methods: {
    emitConfirm: function () {
      this.modalActive = false;
      this.$emit('onAction');
    },
    emitCancel: function () {
      this.modalActive = false;
      this.$emit('onCancel');
    }
  }
};
</script>
<style scoped>
.button-minimal-size {
  display: flex;
  justify-content: flex-start;
}
</style>
