<template>
  <div>
    <div v-if="label != null && label !== ''" class="displayLabel">
      {{ label }}
    </div>
    <div :class="bodyCss">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      default: null,
      type: String,
      required: false
    },
    // Adapts the styling of this component in case this is the
    // first SubContainer in a number of DisplaySubContainers that are put into a DisplayContainer and there is no label
    firstSubComponent: {
      default: false,
      type: Boolean,
      required: false
    },
    // Adapts the styling of this component in case this is the
    // last SubContainer in a number of DisplaySubContainers that are put into a DisplayContainer
    lastSubComponent: {
      default: false,
      type: Boolean,
      required: false
    }
  },
  computed: {
    bodyCss: function () {
      if (
        this.firstSubComponent === true &&
        (this.label == null || this.label === '')
      ) {
        return 'displayBodyLastContainer';
      } else if (this.lastSubComponent === true) {
        return 'displayBodyLastContainer';
      } else {
        return 'displayBody';
      }
    }
  }
};
</script>

<style scoped lang="scss">
.displayLabel {
  color: var(--goe-fontColor-highlight);
}

.displayBody {
  padding: var(--goe-spacing-1);
}
.displayBodyFirstContainer {
  padding: var(--goe-spacing-0) var(--goe-spacing-1) var(--goe-spacing-1);
}

.displayBodyLastContainer {
  padding: var(--goe-spacing-1) var(--goe-spacing-1) var(--goe-spacing-0);
}
</style>
