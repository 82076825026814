var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"statistic-card",attrs:{"width":"300","min-height":_vm.height,"loading":_vm.loading}},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"primary","height":"5","indeterminate":""}})],1),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6 title-line-height"},[_vm._v(" "+_vm._s(_vm.headline)+" "),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"id":"info-incomplete-statistic-metrics","color":_vm.incomplete ? '#dc0000' : 'inherit'}},'v-icon',attrs,false),on),[_vm._v(" mdi-information ")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.description))]),_c('br'),(_vm.descriptionDataSource !== '')?_c('span',[_vm._v(" "+_vm._s(_vm.descriptionDataSource)+" ")]):_vm._e(),(_vm.incomplete)?_c('ul',[_c('li',[_vm._v("Die Daten für diese Kennzahlen sind unvollständig")])]):_vm._e()])],1)],1)],1),_c('v-card-text',[_c('table',{staticClass:"statistic-metrics-card"},[_c('tr',[_c('td',[(
              _vm.endDateValue != null &&
                _vm.endDateValue != undefined &&
                _vm.startDateValue != null &&
                _vm.startDateValue != undefined
            )?_c('h1',{style:(_vm.getDifferenceColor())},[_vm._v(" "+_vm._s(_vm.differenceValue > 0 ? ("+" + _vm.differenceValue) : _vm.differenceValue)+" ")]):_c('h1',[_vm._v(" - ")])]),_c('td',[_c('h4',[_vm._v("Verträge")])])]),_c('tr',[_c('td',[(_vm.endDateValue != null && _vm.endDateValue != undefined)?_c('h1',[_vm._v(" "+_vm._s(_vm.endDateValue)+" ")]):_c('h1',[_vm._v(" - ")])]),_c('td',[_c('h4',[_vm._v("Verträge")])])])])])],2)}
var staticRenderFns = []

export { render, staticRenderFns }