var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:!_vm.isSubcomponent ? 'completeDisplay' : ''},[_c('div',{class:{
      'group-description': true,
      empty:
        _vm.selectionConfiguration && _vm.isFoldable === true && _vm.isSelected === false
    }},[_c('span',{staticClass:"displayLabel"},[(_vm.icon)?_c('v-icon',{staticClass:"displayLabel"},[_vm._v(_vm._s(_vm.icon))]):_vm._e(),_vm._t("label",function(){return [_vm._v(_vm._s(_vm.label))]})],2),(_vm.selectionConfiguration)?_c('span',{staticClass:"toggle-description"},[_vm._v(_vm._s(_vm.selectionConfiguration.label)+":")]):_vm._e(),(_vm.selectionConfiguration)?_c('toggle-button',{attrs:{"disabled":_vm.disabled === true,"labels":{ checked: 'Ja', unchecked: 'Nein' },"color":{
        checked: 'var(--goe-color-success)',
        unchecked: 'var(--goe-fontColor-light)'
      }},model:{value:(_vm.isSelected),callback:function ($$v) {_vm.isSelected=$$v},expression:"isSelected"}}):_vm._e()],1),(
      !_vm.selectionConfiguration ||
        (_vm.selectionConfiguration && (!_vm.isFoldable || _vm.isSelected === true))
    )?_c('div',{class:!_vm.isSubcomponent ? 'displayBodyMainComponent' : 'displayBodySubComponent'},[_vm._t("default")],2):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }