<template>
  <v-card
    class="statistic-card"
    width="300"
    :min-height="height"
    :loading="loading"
  >
    <template slot="progress">
      <v-progress-linear
        color="primary"
        height="5"
        indeterminate
      ></v-progress-linear>
    </template>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6 title-line-height">
          {{ headline }}
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                id="info-incomplete-statistic-metrics"
                v-bind="attrs"
                :color="incomplete ? '#dc0000' : 'inherit'"
                v-on="on"
              >
                mdi-information
              </v-icon>
            </template>
            <span>{{ description }}</span>
            <br />
            <span v-if="descriptionDataSource !== ''">
              {{ descriptionDataSource }}
            </span>
            <ul v-if="incomplete">
              <li>Die Daten für diese Kennzahlen sind unvollständig</li>
            </ul>
          </v-tooltip>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-card-text>
      <table class="statistic-metrics-nested">
        <tr>
          <td>
            <h1
              v-if="
                endDateValue != null &&
                  endDateValue != undefined &&
                  startDateValue != null &&
                  startDateValue != undefined
              "
              :style="getDifferenceColor(differenceValue)"
            >
              {{
                differenceValue > 0 ? `+${differenceValue}` : differenceValue
              }}
            </h1>
            <h1 v-else>
              -
            </h1>
          </td>
          <td>
            <h4>Verträge</h4>
          </td>
        </tr>
        <tr>
          <td>
            <h1 v-if="endDateValue != null && endDateValue != undefined">
              {{ endDateValue }}
            </h1>
            <h1 v-else>
              -
            </h1>
          </td>
          <td>
            <h4>Verträge</h4>
          </td>
        </tr>
      </table>
    </v-card-text>
    <div v-if="tall" style="height: 46px; width: 5px"></div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn icon @click="show = !show">
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-card-text v-if="nestedTable">
          <v-row>
            <v-col cols="2">
              <v-icon color="primary">
                mdi-shape-polygon-plus
              </v-icon>
            </v-col>
            <v-col>
              <table class="statistic-metrics-nested">
                <tr
                  v-if="
                    nestedEndDateValue != null &&
                      nestedEndDateValue != undefined &&
                      nestedStartDateValue != null &&
                      nestedStartDateValue != undefined &&
                      nestedDifferenceValue != 0
                  "
                >
                  <td>
                    <h1 :style="getDifferenceColor(nestedDifferenceValue)">
                      {{
                        nestedDifferenceValue > 0
                          ? `+${nestedDifferenceValue}`
                          : nestedDifferenceValue
                      }}
                    </h1>
                  </td>
                  <td>
                    <h4 class="statistic-metrics-nested-project-label">
                      Projekte
                    </h4>
                  </td>
                </tr>
                <tr>
                  <td>
                    <h1
                      v-if="
                        nestedEndDateValue != null &&
                          nestedEndDateValue != undefined
                      "
                    >
                      {{ nestedEndDateValue }}
                    </h1>
                    <h1 v-else>
                      -
                    </h1>
                  </td>
                  <td>
                    <h4>Projekte in Bau</h4>
                  </td>
                </tr>
              </table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="nestedText">
          <v-row>
            <v-col cols="2">
              <v-icon color="primary">
                mdi-cash-multiple
              </v-icon>
            </v-col>
            <v-col>
              <h4 class="text-subtitle-2">
                {{ nestedTextHeadline }}
              </h4>
            </v-col>
          </v-row>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'StatisticMetricsNestedMetricsCard',
  components: {
    VueApexCharts
  },
  props: {
    headline: {
      type: String,
      required: true
    },
    height: {
      type: String,
      default: 'auto'
    },
    endDateValue: {
      type: Number,
      default: null
    },
    startDateValue: {
      type: Number,
      default: null
    },
    nestedEndDateValue: {
      type: Number,
      default: null
    },
    nestedStartDateValue: {
      type: Number,
      default: null
    },
    loading: {
      type: Boolean,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    descriptionDataSource: {
      type: String,
      default: ''
    },
    tall: {
      type: Boolean,
      default: false
    },
    nestedTable: {
      type: Boolean,
      default: false
    },
    nestedText: {
      type: Boolean,
      default: false
    },
    nestedTextHeadline: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    show: false
  }),
  computed: {
    isSeriesValid: function () {
      return !(
        this.series.some((value) => value === null) || this.seriesSum() <= 0
      );
    },
    nestedDifferenceValue: function () {
      return this.nestedEndDateValue - this.nestedStartDateValue;
    },
    differenceValue: function () {
      return this.endDateValue - this.startDateValue;
    },
    incomplete: function () {
      return (
        (this.nestedTable &&
          (this.nestedStartDateValue == null ||
            this.nestedStartDateValue === undefined ||
            this.nestedEndDateValue == null ||
            this.nestedEndDateValue === undefined)) ||
        this.endDateValue == null ||
        this.endDateValue === undefined ||
        this.startDateValue == null ||
        this.startDateValue === undefined
      );
    }
  },
  methods: {
    getDifferenceColor(value) {
      if (value > 0) {
        return 'color: green';
      } else if (value < 0) {
        return 'color: #dc0000';
      } else {
        return 'color: #666666';
      }
    },
    seriesSum() {
      var sum = 0;
      this.series.forEach((element) => {
        sum = sum + element;
      });
      return sum;
    }
  }
};
</script>
<style lang="scss" scoped>
.statistic-metrics-nested {
  color: rgba(0, 0, 0, 0.6);
  text-align: right;
}

.title-line-height {
  line-height: 1.2 !important;
}

.statistic-metrics-nested td {
  vertical-align: 1px;
  padding-left: 5px;
}
.statistic-metrics-nested-project-label {
  text-align: left;
}
</style>
