<template>
  <div class="input-wrapper" :class="error != null ? 'error' : ''">
    <div class="description" :class="{ focused: focused }">
      <span v-if="description != null">
        {{ description }}
      </span>
      <span v-if="error != null">
        {{ error.message }}
      </span>
      <span v-if="isMandatory"> * </span>
    </div>
    <textarea
      v-if="elementType === 'textarea'"
      :class="[customClass, lightInput ? 'light-input' : '']"
      :type="type"
      :value="value"
      :min="min"
      :max="max"
      @input="onChange"
      @focus="focused = true"
      @blur="onVisited(false), (focused = false)"
    />
    <input
      v-else
      :class="[customClass, lightInput ? 'light-input' : '']"
      :type="type"
      :value="value"
      :min="min"
      :max="max"
      :placeholder="placeholder"
      @input="onChange"
      @focus="focused = true"
      @blur="onVisited(false), (focused = false)"
    />
  </div>
</template>

<script>
import GoetelInputValidationError from './GoetelInputValidationError.js';
import Logger from '../../main/Logger.js';

const LOGGER_KEY = 'GOETEL_INPUT';

export default {
  name: 'GoetelInput',
  props: {
    description: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: false
    },
    inputId: {
      type: String,
      required: true
    },
    type: {
      type: String,
      required: false,
      default: 'text'
    },
    value: {
      type: String,
      required: false,
      default: ''
    },
    customClass: {
      type: String,
      required: false,
      default: ''
    },
    isMandatory: {
      type: Boolean,
      required: false,
      default: false
    },
    currentErrors: {
      type: Array,
      required: false,
      default: () => []
    },
    visitedInputFields: {
      type: Array,
      required: false,
      default: () => []
    },
    elementType: {
      type: String,
      required: false,
      default: 'input'
    },
    regExp: {
      type: RegExp,
      required: false,
      default: null
    },
    min: {
      type: String,
      required: false,
      default: null
    },
    max: {
      type: String,
      required: false,
      default: null
    },
    lightInput: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
    error: null,
    currentValue: '',
    focused: false
  }),
  watch: {
    currentErrors: function (value, _) {
      this.error = value.filter((item) => item.key === this.$props.inputId)[0];
    }
  },
  mounted: function () {
    this.currentValue = this.value;
  },
  methods: {
    onChange(event) {
      Logger.log(LOGGER_KEY, 'validate input called');

      const key = this.$props.inputId;
      const isMandatory = this.$props.isMandatory;
      const value = event != null ? event.target.value : this.currentValue;

      const errors = this.$props.currentErrors.filter(
        (item) => item.key !== key
      );

      // Check for validation error
      if (isMandatory && !value.length) {
        errors.push(
          new GoetelInputValidationError(key, null, 'muss gesetzt sein.')
        );
        Logger.log(LOGGER_KEY, 'valiation result == non valid (mandatory)');
      }

      if (this.$props.regExp !== null && !this.$props.regExp.test(value)) {
        if (isMandatory || (value !== null && value !== '')) {
          errors.push(new GoetelInputValidationError(key));
          Logger.log(LOGGER_KEY, 'valiation result == non valid (regExp)');
        }
      }

      this.currentValue = value;

      this.$emit('onError', errors);
      this.$emit('onChange', value);

      this.onVisited(true);
    },
    onVisited(calledByOnChange = false) {
      Logger.log(LOGGER_KEY, 'onVisited ' + this.$props.inputId);

      const inputId = this.$props.inputId;
      const visited = this.$props.visitedInputFields;

      if (!visited.includes(inputId)) {
        visited.push(inputId);
      }

      // if (!calledByOnChange) this.onChange();

      this.$emit('onVisited', visited);
    }
  }
};
</script>

<style scoped>
.input-wrapper {
  margin-bottom: var(--goe-spacing-2);
}

input,
textarea,
.description {
  width: calc(100% - 4px);
}

input,
textarea {
  padding: 0 15px;
}

.description {
  font-size: var(--goe-fontSize-small);
  color: var(--goe-fontColor-light);
}

.description.focused {
  color: var(--goe-fontColor-highlightDark);
}

.error .description {
  color: var(--goe-color-error);
  font-weight: var(--goe-fontWeight-bold);
}

.light-input {
  background: var(--goe-background-light);
}

.padded {
  -moz-padding-start: calc(10px - 3px);
  padding-left: 10px;
  width: calc(100% - 14px);
}
</style>
