<template>
  <div :class="!isSubcomponent ? 'completeDisplay' : ''">
    <!-- Header -->
    <div
      :class="{
        'group-description': true,
        empty:
          selectionConfiguration && isFoldable === true && isSelected === false
      }"
    >
      <span class="displayLabel">
        <v-icon v-if="icon" class="displayLabel">{{ icon }}</v-icon>
        <slot name="label">{{ label }}</slot>
      </span>
      <span
        v-if="selectionConfiguration"
        class="toggle-description"
      >{{ selectionConfiguration.label }}:</span>
      <toggle-button
        v-if="selectionConfiguration"
        v-model="isSelected"
        :disabled="disabled === true"
        :labels="{ checked: 'Ja', unchecked: 'Nein' }"
        :color="{
          checked: 'var(--goe-color-success)',
          unchecked: 'var(--goe-fontColor-light)'
        }"
      />
    </div>
    <div
      v-if="
        !selectionConfiguration ||
          (selectionConfiguration && (!isFoldable || isSelected === true))
      "
      :class="
        !isSubcomponent ? 'displayBodyMainComponent' : 'displayBodySubComponent'
      "
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    // Label that is displayed
    label: {
      type: String,
      required: false,
      default: null
    },
    // If true, this component will be treated as subcomponent
    isSubcomponent: {
      type: Boolean,
      required: false,
      default: false
    },
    // If not null this will be treated as selectable container
    // {label: String, isSelected: Boolean, isFoldable: Boolean, disabled: Boolean}
    selectionConfiguration: {
      type: Object,
      required: false,
      default: null
    },
    icon: {
      type: String,
      required: false,
      default: null
    }
  },
  data: () => ({
    isSelected: null,
    isFoldable: null,
    disabled: null // Indicates whether or not the radio button is disabled or not
  }),
  watch: {
    'selectionConfiguration.disabled': {
      handler: function (newValue, _) {
        if (newValue !== null && newValue !== undefined) {
          this.disabled = newValue;
        } else {
          this.disabled = null;
        }
      },
      immediate: true
    },
    'selectionConfiguration.isSelected': {
      handler: function (newValue, _) {
        if (this.selectionConfiguration) {
          if (newValue !== null && newValue !== undefined) {
            this.isSelected = newValue;
          } else {
            this.isSelected = false;
          }
        } else {
          this.isSelected = null;
        }
      },
      immediate: true
    },
    isSelected: function (newValue, _) {
      this.$emit('update:isSelected', newValue);
    }
  },
  created() {
    if (
      this.selectionConfiguration !== null &&
      this.selectionConfiguration !== undefined
    ) {
      this.isSelected = this.selectionConfiguration.isSelected;
      this.isFoldable = this.selectionConfiguration.isFoldable;
    }
  }
};
</script>

<style scoped lang="scss">
.group-description {
  display: grid;
  grid-template-columns: auto max-content var(--goe-spacing-5_5);
  .toggle-description {
    color: var(--goe-fontColor-light);
    font-size: var(--goe-fontSize-small);
    margin: auto var(--goe-spacing-1);
  }
}

.empty {
  border-bottom: var(--goe-borderSize-normal) solid var(--goe-borderColor-clear);
  padding-bottom: var(--goe-spacing-1);
}

.completeDisplay {
  margin-bottom: var(--goe-spacing-2);
}

.displayLabel {
  color: var(--goe-fontColor-highlight);
}

.displayBodyMainComponent {
  padding: var(--goe-spacing-2);
  background: var(--goe-background-highlight);
  font-size: var(--goe-fontSize-small);
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: repeat(auto);
}

.displayBodySubComponent {
  padding: var(--goe-spacing-0) var(--goe-spacing-1) var(--goe-spacing-0);
}
</style>
