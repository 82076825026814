<template>
  <v-card
    width="300"
    :min-height="height"
    class="statistic-card"
    :loading="loading"
  >
    <template slot="progress">
      <v-progress-linear
        color="primary"
        height="5"
        indeterminate
      ></v-progress-linear>
    </template>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6 title-line-height">
          {{ headline }}
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                id="info-incomplete-statistic-metrics"
                v-bind="attrs"
                :color="incomplete ? '#dc0000' : 'inherit'"
                v-on="on"
              >
                mdi-information
              </v-icon>
            </template>
            <span>{{ description }}</span>
            <br />
            <span v-if="descriptionDataSource !== ''">
              {{ descriptionDataSource }}
            </span>
            <ul v-if="incomplete">
              <li>Die Daten für diese Kennzahlen sind unvollständig</li>
            </ul>
          </v-tooltip>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-card-text>
      <table class="statistic-metrics-card">
        <tr>
          <td>
            <h1
              v-if="
                endDateValue != null &&
                  endDateValue != undefined &&
                  startDateValue != null &&
                  startDateValue != undefined
              "
              :style="getDifferenceColor()"
            >
              {{
                differenceValue > 0 ? `+${differenceValue}` : differenceValue
              }}
            </h1>
            <h1 v-else>
              -
            </h1>
          </td>
          <td>
            <h4>Verträge</h4>
          </td>
        </tr>
        <tr>
          <td>
            <h1 v-if="endDateValue != null && endDateValue != undefined">
              {{ endDateValue }}
            </h1>
            <h1 v-else>
              -
            </h1>
          </td>
          <td>
            <h4>Verträge</h4>
          </td>
        </tr>
      </table>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'StatisticMetricsCard',
  props: {
    headline: {
      type: String,
      required: true
    },
    endDateValue: {
      type: Number,
      default: null
    },
    startDateValue: {
      type: Number,
      default: null
    },
    loading: {
      type: Boolean,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    descriptionDataSource: {
      type: String,
      default: ''
    },
    height: {
      type: String,
      default: 'auto'
    },
    tall: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    show: false
  }),
  computed: {
    differenceValue: function () {
      return this.endDateValue - this.startDateValue;
    },
    incomplete: function () {
      return (
        this.endDateValue == null ||
        this.endDateValue === undefined ||
        this.startDateValue == null ||
        this.startDateValue === undefined
      );
    }
  },
  methods: {
    getDifferenceColor() {
      if (this.differenceValue > 0) {
        return 'color: green';
      } else if (this.differenceValue < 0) {
        return 'color: #dc0000';
      } else {
        return 'color: #666666';
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.statistic-metrics-card {
  text-align: right;
}

.title-line-height {
  line-height: 1.2 !important;
}
.statistic-metrics-card td {
  vertical-align: 1px;
  padding-left: 5px;
}
</style>
