<template>
  <v-card
    class="statistic-card"
    width="300"
    :min-height="height"
    :loading="loading"
  >
    <template slot="progress">
      <v-progress-linear
        color="primary"
        height="5"
        indeterminate
      ></v-progress-linear>
    </template>
    <v-list-item>
      <v-list-item-content>
        <v-list-item-title class="text-h6 title-line-height">
          {{ headline }}
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                id="info-incomplete-statistic-metrics"
                v-bind="attrs"
                :color="incomplete ? '#dc0000' : 'inherit'"
                v-on="on"
              >
                mdi-information
              </v-icon>
            </template>
            <span>{{ description }}</span>
            <br />
            <span v-if="descriptionDataSource !== ''">
              {{ descriptionDataSource }}
            </span>
            <ul v-if="incomplete">
              <li>Die Daten für diese Kennzahlen sind unvollständig</li>
            </ul>
          </v-tooltip>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
    <v-card-text>
      <table class="statistic-metrics-pie">
        <tr>
          <td>
            <h1
              v-if="
                endDateValue != null &&
                  endDateValue != undefined &&
                  startDateValue != null &&
                  startDateValue != undefined
              "
              :style="getDifferenceColor()"
            >
              {{
                differenceValue > 0 ? `+${differenceValue}` : differenceValue
              }}
            </h1>
            <h1 v-else>
              -
            </h1>
          </td>
          <td>
            <h4>Verträge</h4>
          </td>
        </tr>
        <tr>
          <td>
            <h1 v-if="endDateValue != null && endDateValue != undefined">
              {{ endDateValue }}
            </h1>
            <h1 v-else>
              -
            </h1>
          </td>
          <td>
            <h4>Verträge</h4>
          </td>
        </tr>
      </table>
    </v-card-text>
    <div v-if="tall" style="height: 46px; width: 5px"></div>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn icon @click="show = !show">
        <v-icon>{{ show ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
      </v-btn>
    </v-card-actions>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-container v-if="isSeriesValid">
          <vue-apex-charts
            :options="options"
            type="pie"
            :series="series"
            :height="pieHeight"
          />
        </v-container>
      </div>
    </v-expand-transition>
  </v-card>
</template>
<script>
import VueApexCharts from 'vue-apexcharts';

export default {
  name: 'StatisticMetricsPieChartCard',
  components: {
    VueApexCharts
  },
  props: {
    headline: {
      type: String,
      required: true
    },
    height: {
      type: String,
      default: 'auto'
    },
    endDateValue: {
      type: Number,
      default: null
    },
    startDateValue: {
      type: Number,
      default: null
    },
    options: {
      type: Object,
      required: true
    },
    series: {
      type: Array,
      required: true
    },
    pieHeight: {
      type: String,
      required: true
    },
    loading: {
      type: Boolean,
      required: true
    },
    description: {
      type: String,
      required: true
    },
    descriptionDataSource: {
      type: String,
      default: ''
    },
    tall: {
      type: Boolean,
      default: false
    },
    invertDifferenceColor: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    show: false
  }),
  computed: {
    isSeriesValid: function () {
      return !(
        this.series.some((value) => value === null) || this.seriesSum() <= 0
      );
    },
    differenceValue: function () {
      return this.endDateValue - this.startDateValue;
    },
    incomplete: function () {
      return (
        this.endDateValue == null ||
        this.endDateValue === undefined ||
        this.startDateValue == null ||
        this.startDateValue === undefined
      );
    }
  },
  methods: {
    getDifferenceColor() {
      if (this.invertDifferenceColor) {
        if (this.differenceValue < 0) {
          return 'color: green';
        } else if (this.differenceValue > 0) {
          return 'color: #dc0000';
        } else {
          return 'color: #666666';
        }
      } else {
        if (this.differenceValue > 0) {
          return 'color: green';
        } else if (this.differenceValue < 0) {
          return 'color: #dc0000';
        } else {
          return 'color: #666666';
        }
      }
    },
    seriesSum() {
      var sum = 0;
      this.series.forEach((element) => {
        sum = sum + element;
      });
      return sum;
    }
  }
};
</script>
<style lang="scss" scoped>
.statistic-metrics-pie {
  text-align: right;
}

.title-line-height {
  line-height: 1.2 !important;
}

.statistic-metrics-pie td {
  vertical-align: 1px;
  padding-left: 5px;
}
</style>
