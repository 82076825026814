var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"button-minimal-size"},[_c('v-dialog',{staticClass:"button-minimal-size",attrs:{"width":"40%","retain-focus":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var dialog = ref.on;
var dialogAttrs = ref.attrs;
return [_c('v-tooltip',{attrs:{"top":_vm.tooltipTop,"bottom":_vm.tooltipBottom,"left":_vm.tooltipLeft},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var tooltip = ref.on;
var tooltipAttrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',tooltipAttrs,false),tooltip),[_c('v-btn',_vm._g(_vm._b({class:[_vm.customButtonClass],attrs:{"disabled":_vm.disabled},on:{"click":function($event){return _vm.$emit('onDialogOpen')}}},'v-btn',Object.assign({}, dialogAttrs,
                _vm.triggerButtonProps),false),dialog),[_c('v-icon',{attrs:{"small":_vm.smallTriggerIcon}},[_vm._v(" "+_vm._s(_vm.iconName)+" ")]),_vm._t("triggerButtonText")],2)],1)]}}],null,true)},[_c('span',[_vm._v(" "+_vm._s(_vm.tooltipText)+" ")])])]}}]),model:{value:(_vm.modalActive),callback:function ($$v) {_vm.modalActive=$$v},expression:"modalActive"}},[_c('v-card',[_c('v-card-title',[_vm._t("dialogTitle",function(){return [_vm._v(" Dialogtitel Slot (Name: dialogTitle) ")]})],2),_c('v-card-text',[_vm._t("dialogText",function(){return [_vm._v(" Dialog Text Slot (Name: dialogText) ")]})],2),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.emitCancel()}}},[_vm._v(" Abbrechen ")]),_c('v-btn',{attrs:{"color":_vm.confirmButtonColor,"disabled":_vm.confirmButtonDisabled},on:{"click":function($event){return _vm.emitConfirm()}}},[_vm._v(" "+_vm._s(_vm.confirmButtonText)+" ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }