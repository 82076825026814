<template>
  <div>
    <div class="label">
      {{ label }}
    </div>
    <div v-if="dataIsLoading" class="loadingAnimation"></div>
    <slot v-else>
      -
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    dataIsLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: true
    }
  }
};
</script>

<style scoped>
@keyframes shine-lines {
  0% {
    background-position: -100px;
  }

  40%,
  100% {
    background-position: 140px;
  }
}

.loadingAnimation {
  float: left;
  width: 7em;
  height: 16px;
  border-radius: 7px;
  background-image: linear-gradient(90deg, #ddd 0px, #e8e8e8 40px, #ddd 80px);
  background-size: 600px;
  background-color: #ddd;
  animation: shine-lines 1.8s infinite linear;
}
</style>
