<template>
  <div class="loader" />
</template>

<script>
export default { name: 'Loader' };
</script>

<style scoped>
.loader {
  width: var(--goe-spacing-4);
  height: var(--goe-spacing-4);
  border: var(--goe-spacing-0_5) solid var(--goe-background-highlight);
  border-top: var(--goe-spacing-0_5) solid var(--goe-background-dark);
  border-radius: 50%;
  animation: loading 2s linear infinite;
}

@keyframes loading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
