<template>
  <div :class="!noPadding ? 'group' : null">
    <div>{{ label }}</div>
    <div
      :class="!noPadding ? 'element-wrapper' : 'element-wrapper-no-padding'"
      :style="white ? 'background-color: white' : ''"
    >
      <div
        :class="{
          flex: flex,
          flexStart: flexStart
        }"
      >
        <slot />
        <slot name="children" />
      </div>
      <div :style="!small ? 'padding: 20px' : ''">
        <slot name="button" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    flex: {
      type: Boolean,
      required: false,
      default: false
    },
    flexStart: {
      type: Boolean,
      required: false,
      default: false
    },
    label: {
      type: String,
      required: false,
      default: undefined
    },
    noPadding: {
      type: Boolean,
      required: false,
      default: false
    },
    small: {
      type: Boolean,
      required: false,
      default: false
    },
    white: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style scoped lang="scss">
.element-wrapper {
  background-color: var(--goe-background-highlight);
  padding: 8px 20px !important;
}
.element-wrapper-no-padding {
  background-color: var(--goe-background-highlight);
  padding: 2px 20px !important;
  padding-top: 10px !important;
}
.flex {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.flexStart {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 20px !important;
  padding-top: 20px !important;
}
</style>
