<template>
  <div class="button-wrapper">
    <div v-if="isLoading" class="button-loading-wrapper">
      <Loader />
    </div>
    <button
      v-else
      :class="[buttonStyle, buttonSize]"
      :disabled="disabled"
      @click="$emit('onClick')"
    >
      {{ description }}
    </button>
  </div>
</template>

<script>
import Loader from './Loader.vue';

export default {
  name: 'NormalButton',
  components: { Loader },
  props: {
    description: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonStyle: {
      type: String,
      required: false,
      default: 'solid'
    },
    isLoading: {
      type: Boolean,
      required: false,
      default: false
    },
    buttonSize: {
      type: String,
      required: false,
      default: 'normal'
    }
  }
};
</script>

<style scoped>
.button-wrapper {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}

button {
  border: none;
  border-radius: var(--goe-spacing-0);
  padding: var(--goe-spacing-1);
  font-size: var(--goe-fontSize-normal);
  cursor: pointer;
}

button.small {
  padding: var(--goe-spacing-0_5);
  font-size: var(--goe-fontSize-small);
}

button:disabled {
  opacity: 0.7;
  cursor: not-allowed;
}

button.solid {
  background: var(--goe-background-dark);
  color: var(--goe-fontColor-white);
  border: var(--goe-borderSize-small) solid var(--goe-borderColor-dark);
}

button.solid:hover {
  background: var(--goe-background-darkHover);
}

button.clear {
  background: var(--goe-background-clear);
  color: var(--goe-fontColor-dark);
  border: var(--goe-borderSize-small) solid var(--goe-borderColor-clear);
}

button.clear:hover {
  background: var(--goe-background-clearHover);
}

button.danger {
  background: var(--goe-background-danger);
  color: var(--goe-fontColor-white);
  border: var(--goe-borderSize-small) solid var(--goe-borderColor-danger);
}

button.danger:hover {
  background: var(--goe-background-dangerHover);
}

button.link {
  background: var(--goe-fontColor-white);
  color: var(--goe-fontColor-dark);
}

.loader {
  margin-top: -5px;
}

.dropdown-reset .button-wrapper {
  width: max-content;
  margin-left: 0em;
  margin-right: var(--goe-spacing-0);
}

.dropdown-reset .button-wrapper .button {
  border: none;
  border-radius: var(--goe-spacing-0);
  padding: var(--goe-spacing-1);
  font-size: var(--goe-fontSize-verySmall);
  cursor: pointer;
}
</style>
