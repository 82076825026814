<template>
  <div>
    <v-btn
      small
      outlined
      color="var(--goe-fontColor-darkish)"
      :href="`https://otrs.goetel.de/ofork/index.pl?Action=AgentCustomerInformationCenter;CustomerID=${customerId}`"
      :disabled="customerId === null"
      target="_blank"
    >
      <v-icon small>
        mdi-ticket
      </v-icon>
      OTRS
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'OTRSLinkButton',
  props: {
    customerId: {
      type: String,
      default: null,
      required: false
    }
  }
};
</script>

<style scoped lang="scss"></style>
