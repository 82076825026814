<template>
  <v-menu
    v-model="datepicker"
    :close-on-content-click="false"
    transition="scale-transition"
    min-width="auto"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :value="dateFormatted"
        :label="label"
        persistent-placeholder
        :placeholder="placeholder"
        prepend-icon="mdi-calendar"
        :dense="dense"
        :disabled="disabled"
        :error="errorMessageAggregate !== ''"
        :error-messages="errorMessageAggregate"
        :outlined="outlined"
        v-bind="attrs"
        @keydown="checkValidCharacter($event)"
        @blur="resetToValidValue"
        @click:prepend="on.click"
        @input="onManualInput($event)"
      />
    </template>
    <v-date-picker
      :value="currentVal"
      :color="color"
      :min="minDate"
      :max="maxDate"
      :disabled="disabled"
      first-day-of-week="1"
      show-week
      :allowed-dates="(date) => checkAllowedDates(date)"
      locale="de-DE"
      @change="$emit('change')"
      @input="onInput($event)"
    />
  </v-menu>
</template>
<script>
import lodash from 'lodash';

export default {
  name: 'DatePicker',
  props: {
    label: {
      type: String,
      default: '',
      required: false
    },
    // Allows to override the standard text in the date selection text field
    placeholder: {
      type: String,
      required: false,
      default: 'Datum auswählen...'
    },
    // Allows to set the styling of the date selection text field to dense in order to allign the styling of the text field with other components
    dense: { type: Boolean, required: false, default: false },
    minDate: {
      type: String,
      default: null,
      required: false
    },
    maxDate: {
      type: String,
      default: null,
      required: false
    },
    value: {
      required: false,
      type: String,
      default: null
    },
    color: {
      required: false,
      type: String,
      default: '#1565C0'
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false
    },
    disableWeekend: {
      type: Boolean,
      default: false,
      required: false
    },
    disableHolidays: {
      type: Boolean,
      default: false,
      required: false
    },
    errorMessage: {
      type: String,
      default: null,
      required: false
    },
    outlined: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  data: () => ({
    datepicker: false,
    dateValue: '',
    lastValidValue: '',
    componentErrors: []
  }),
  computed: {
    dateFormatted() {
      return this.formatDate(this.dateValue);
    },
    currentVal() {
      const isIsoDate = !!this.dateValue && this.dateValue.includes('-');
      if ((this.dateValue === null || !isIsoDate) && !!this.minDate) {
        return this.minDate;
      } else if ((this.dateValue === null || !isIsoDate) && !!this.maxDate) {
        return this.maxDate;
      } else if (!isIsoDate) {
        return null;
      } else {
        return this.value;
      }
    },
    errorMessageAggregate() {
      const messages = this.componentErrors.slice();
      if (this.errorMessage) {
        messages.unshift(this.errorMessage);
      }
      return messages.join('\n');
    }
  },
  watch: {
    value: {
      handler: function (newValue, oldValue) {
        this.dateValue = newValue;
        this.componentErrors = [];
        this.validateInputDate(newValue);
      },
      immediate: true
    }
  },
  methods: {
    onInput: function (event) {
      this.datepicker = false;
      this.$emit('input', event);
    },
    onManualInput: function (event) {
      let emitValue = event;
      this.componentErrors = [];
      if (this.checkIsValidManualDateString(event)) {
        emitValue = this.convertManualDateString(event);
        this.validateInputDate(emitValue);
        if (this.componentErrors.length === 0) {
          this.$emit('input', emitValue);
        }
      } else {
        this.validateIncompleteDateString(emitValue);
      }
      this.dateValue = event;
    },
    resetToValidValue() {
      if (this.componentErrors.length !== 0) {
        this.dateValue = this.lastValidValue;
        this.componentErrors = [];
        this.validateInputDate(this.dateValue);
      }
    },
    checkValidCharacter(event) {
      console.log(event.key);
      if (event.key.match(/^.{1}$/) && !event.key.match(/^[\d.]+$/)) {
        event.preventDefault();
      }
    },
    checkAllowedDates(date) {
      var allowedDate;
      if (this.disableWeekend && this.disableHolidays) {
        allowedDate = this.checkWeekDay(date);
        if (allowedDate) {
          allowedDate = this.checkHolidays(date);
        }
      } else if (this.disableWeekend) {
        allowedDate = this.checkWeekDay(date);
      } else if (this.disableHolidays) {
        allowedDate = this.checkHolidays(date);
      } else {
        allowedDate = true;
      }
      return allowedDate;
    },
    checkWeekDay(date) {
      const weekday = new Date(date).getDay();
      return weekday !== 0 && weekday !== 6;
    },
    checkHolidays(date) {
      const year = new Date(date).getFullYear();
      const easterSunday = this.easterFormula(year);
      var allowedDay = true;
      switch (date) {
        // Check for Karfreitag
        case this.formatToYYYYMMDD(this.addDays(easterSunday, -2)):
          allowedDay = false;
          break;
        // Check for Ostermontag
        case this.formatToYYYYMMDD(this.addDays(easterSunday, 1)):
          allowedDay = false;
          break;
        // Check for Christi Himmelfahrt
        case this.formatToYYYYMMDD(this.addDays(easterSunday, 39)):
          allowedDay = false;
          break;
        // Check for Pfingstmontag
        case this.formatToYYYYMMDD(this.addDays(easterSunday, 50)):
          allowedDay = false;
          break;
        // Check for Fronleichnam
        case this.formatToYYYYMMDD(this.addDays(easterSunday, 60)):
          allowedDay = false;
          break;
        // Check for Neujahr
        case year + '-01-01':
          allowedDay = false;
          break;
        // Check for Tag der Arbeit
        case year + '-05-01':
          allowedDay = false;
          break;
        // Check for Tag der deutschen Einheit
        case year + '-10-03':
          allowedDay = false;
          break;
        // Check for Reformationstag
        case year + '-10-31':
          allowedDay = false;
          break;
        // Check for Allerheiligen
        // case year + '-11-01':
        //   allowedDay = false;
        //   break;
        // Check for 1. Weihnachtsfeiertag
        case year + '-12-25':
          allowedDay = false;
          break;
        // Check for 2. Weihnachtsfeiertag
        case year + '-12-26':
          allowedDay = false;
          break;
      }
      return allowedDay;
    },
    addDays(easterSunday, days) {
      const date = new Date(easterSunday);
      date.setDate(date.getDate() + days);
      return date;
    },
    formatToYYYYMMDD(date) {
      const formattedDate = new Date();
      const offset = date.getTimezoneOffset() * 60000;
      formattedDate.setTime(date.getTime() - offset);
      return formattedDate.toISOString().split('T')[0];
    },
    easterFormula(year) {
      // https://de.wikipedia.org/wiki/Gaußsche_Osterformel
      const a = year % 4;
      const b = year % 7;
      const c = year % 19;
      const d = (19 * c + 24) % 30;
      const e = (2 * a + 4 * b + 6 * d + 5) % 7;
      const f = Math.floor((c + 11 * d + 22 * e) / 451);
      var easterDayInMonth = 22 + d + e - 7 * f;
      // If easter is greater than 31 easter sunday is in April otherwise in March
      var month = 0;
      if (easterDayInMonth > 31) {
        easterDayInMonth = d + e - 7 * f - 9;
        month = 3;
      } else {
        month = 2;
      }
      const easterSunday = new Date();
      easterSunday.setFullYear(year);
      easterSunday.setMonth(month);
      easterSunday.setDate(easterDayInMonth);
      easterSunday.setHours(0, 0, 0, 0);
      return easterSunday;
    },
    formatDate(date) {
      if (!date) {
        return null;
      }
      if (!date.includes('-')) {
        return date;
      }

      const [year, month, day] = date.split('-');
      return `${day}.${month}.${year}`;
    },
    convertManualDateString(dateString) {
      if (!dateString) {
        return null;
      }
      const [day, month, year] = dateString.split('.');
      return `${year}-${month}-${day}`;
    },
    checkIsValidManualDateString(date) {
      if (!date) {
        return false;
      }
      if (date.length !== 10) {
        return false;
      }
      const isoDate = this.convertManualDateString(date);
      const dateTimestamp = Date.parse(isoDate);
      return !isNaN(dateTimestamp);
    },
    validateInputDate: lodash.debounce(
      function (dateString) {
        const dateInput = Date.parse(dateString);
        // Check parseable datestrings for the correct date range -> minDate, maxDate, weekends, holidays
        if (
          dateInput > Date.parse(this.maxDate) ||
          dateInput < Date.parse(this.minDate)
        ) {
          this.componentErrors.push('Ungültiges Datum');
          return;
        }
        if (!this.checkAllowedDates(dateString)) {
          this.componentErrors.push('Ungültiges Datum');
          return;
        }
        this.lastValidValue = dateString;
      },
      300,
      {
        leading: true
      }
    ),
    validateIncompleteDateString: lodash.debounce(
      function (dateString) {
        const isIsoDateInput = !!dateString && dateString.includes('-');
        if (dateString.length !== 10 || !isIsoDateInput) {
          this.componentErrors.push(
            'Ungültiges Datumsformat. Bitte verwenden Sie folgendes Format für die Eingabe: DD.MM.YYYY'
          );
          return;
        }
        const validInput = dateString.match(
          /^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/
        );
        if (!validInput) {
          this.componentErrors.push(
            'Ungültiges Datumsformat. Bitte verwenden Sie folgendes Format für die Eingabe: DD.MM.YYYY'
          );
        }
      },
      300,
      { leading: true }
    )
  }
};
</script>
<style scoped>
.v-input {
  width: calc(100% - 4px);
}
</style>
