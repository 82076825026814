<template>
  <v-tooltip
    :top="tooltipTop"
    :bottom="tooltipBottom"
    :left="tooltipLeft"
  >
    <template v-slot:activator="{ on: tooltip, attrs: tooltipAttrs }">
      <v-icon
        :small="smallTriggerIcon"
        v-bind="tooltipAttrs"
        v-on="tooltip"
      >
        {{ iconName }}
      </v-icon>
    </template>
    <span> {{ tooltipText }} </span>
  </v-tooltip>
</template>
<script>
export default {
  name: 'IconWithTooltip',
  props: {
    tooltipTop: {
      type: Boolean,
      default: false,
      required: false
    },
    tooltipBottom: {
      type: Boolean,
      default: false,
      required: false
    },
    tooltipLeft: {
      type: Boolean,
      default: false,
      required: false
    },
    tooltipRight: {
      type: Boolean,
      default: false,
      required: false
    },
    // icon name for the trigger-button icon.
    iconName: {
      type: String,
      required: true
    },
    tooltipText: {
      type: String,
      required: true
    },
    // Object that can contain props that will be directly set on the v-button triggering the dialog
    triggerButtonProps: {
      type: Object,
      required: false,
      default: () => {
        return { icon: true };
      }
    },
    confirmButtonColor: {
      type: String,
      required: false,
      default: 'primary'
    },
    // Boolean prop that can be used to disable the confirmation button in the dialog.
    confirmButtonDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    // Custom classes (comma separated) for the button that triggers the dialog.
    customButtonClass: {
      type: String,
      required: false,
      default: null
    },
    // If true, the icon of the trigger button will get the 'small' prop
    smallTriggerIcon: {
      type: Boolean,
      required: false,
      default: false
    },
    // If true, the trigger-button will be disabled.
    disabled: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data: () => ({
  }),
  methods: {
  }
};
</script>
<style scoped></style>
