<template>
  <button
    :class="[
      buttonType,
      { bordered: withBorder },
      { fixedWidth: description === null }
    ]"
    @click="$emit('onClick')"
  >
    <span v-if="description">{{ description }}</span>
    <img :src="src" />
  </button>
</template>

<script>
export default {
  name: 'ImageButton',
  props: {
    src: {
      type: String,
      required: true
    },
    description: {
      type: String,
      required: false,
      default: null
    },
    buttonType: {
      type: String,
      required: false,
      default: 'solid'
    },
    withBorder: {
      type: Boolean,
      required: false,
      default: false
    }
  }
};
</script>

<style scoped>
.button-wrapper {
  width: max-content;
  margin-left: auto;
  margin-right: var(--goe-spacing-0);
}

button {
  border: none;
  border-radius: var(--goe-spacing-0);
  padding: var(--goe-spacing-0_5);
  cursor: pointer;
  background: none;
  display: inline-grid;
  grid-template-columns: max-content auto;
  grid-gap: var(--goe-spacing-1);
}

button > span {
  margin: auto;
  text-transform: uppercase;
  color: var(--goe-fontColor-dark);
  font-weight: var(--goe-fontWeight-bold);
}

button.fixedWidth {
  width: var(--goe-spacing-3);
  height: var(--goe-spacing-3);
}

button.solid {
  background: var(--goe-background-dark);
}

button.bordered {
  border: var(--goe-borderSize-small) solid var(--goe-borderColor-clearer);
  border-radius: var(--goe-borderRadius-normal);
}

img {
  width: var(--goe-spacing-2);
  height: var(--goe-spacing-2);
  padding: relative;
  margin: var(--goe-spacing-0);
}
</style>
