<template>
  <div
    class="checkbox"
    :class="[{ isChecked: isChecked }, { rounded: rounded }]"
    @click="$emit('onCheckedToggle', !isChecked)"
  />
</template>

<script>
export default {
  name: 'Checkbox',
  props: {
    isChecked: {
      type: Boolean,
      required: true
    },
    rounded: {
      type: Boolean,
      required: false,
      default: true
    }
  }
};
</script>

<style scoped>
.checkbox {
  cursor: pointer;
  user-select: none;
  background: var(--goe-background-light);
  border: var(--goe-borderSize-small) solid var(--goe-borderColor-clearer);
  height: var(--goe-spacing-1_8);
  width: var(--goe-spacing-1_8);
  border-radius: var(--goe-borderRadius-normal);
}

.rounded {
  border-radius: 50%;
}

.checkbox.isChecked {
  background: var(--goe-background-darker);
}
</style>
