<template>
  <div>
    <v-btn
      small
      outlined
      color="var(--goe-fontColor-darkish)"
      :href="`https://ocs.outbox.de/wbci/furtherInformation/${wbciId}`"
      target="_blank"
      :disabled="!wbciId"
    >
      <v-icon small>
        mdi-alpha-o-circle
      </v-icon>
      OCS
    </v-btn>
  </div>
</template>

<script>
export default {
  name: 'OCSLinkButton',
  props: {
    wbciId: { type: String, required: false, default: null }
  }
};
</script>

<style scoped lang="scss"></style>
