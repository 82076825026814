<template>
  <div>
    <v-alert
      dense
      outlined
      type="error"
    >
      <slot></slot>
    </v-alert>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
